import React, { useState } from 'react';
import './Calculator.css';

import { Container, Input } from 'reactstrap';

function Calculator() {
  const [length, setLength] = useState('0');
  const [width, setWidth] = useState('0');
  const [smallLetters, setSmallLetters] = useState('0');
  const [bigLetters, setBigLetters] = useState('0');

  return (
    <Container className="contentWrapper">
      <h1 id="myWorksH1">Beregn prisen på dit træskilt</h1>
      <h4 className="beregnText">
        Ønsker du en særbestilling, så er du velkommen til at kontakte mig.{' '}
        <br />
        Fragt og levering kommer er ikke medregnet denne pris.
      </h4>
      <div id="beregnSection">
        <h2 className="headlines">Vælg størrelsen på skiltet:</h2>
        <h4 className="beregnText">
          Prisen er 0,25 øre pr. cm<sup>2</sup>
        </h4>
        {/* Længde */}
        <h4 className="beregnText">
          Længde:{' '}
          <Input
            className="rangeInput"
            type="text"
            min="0"
            max="250"
            value={length}
            onChange={(event) => setLength(event.target.value)}
          />{' '}
          cm
        </h4>
        <input
          className="slider sizeSlider"
          type="range"
          min="0"
          max="250"
          value={length}
          onChange={(event) => setLength(event.target.value)}
        />
        {/* Bredde */}
        <h4 className="beregnText">
          Bredde:{' '}
          <Input
            className="rangeInput ml-1"
            type="text"
            min="0"
            max="50"
            value={width}
            onChange={(event) => setWidth(event.target.value)}
          />{' '}
          cm
        </h4>
        <input
          className="slider sizeSlider"
          type="range"
          min="0"
          max="50"
          value={width}
          onChange={(event) => setWidth(event.target.value)}
        />
        <h4 className="beregnText">
          Skiltets størrelse: <strong>{length * width}</strong> cm<sup>2</sup>
        </h4>

        <h2 className="headlines">
          Vælg mellem laserskåret eller håndlavet bogstaver:
        </h2>
        <h4 className="beregnText">
          Laserskåret er min. 0.5 cm og derover. <br />
          Pris pr. laserskåret bogstav er 20 kr.
        </h4>
        <h4 className="beregnText">
          Laserskåret bogstaver:{' '}
          <Input
            className="rangeInput"
            type="text"
            min="0"
            max="50"
            value={smallLetters}
            onChange={(event) => setSmallLetters(event.target.value)}
          />{' '}
          stk
        </h4>
        <input
          className="slider letterSlider"
          type="range"
          min="0"
          max="50"
          value={smallLetters}
          onChange={(event) => setSmallLetters(event.target.value)}
        />
        <h4 className="beregnText">Pris pr. håndlavet bogstav er 35 kr.</h4>
        <h4 className="beregnText">
          Håndlavet bogstaver:{' '}
          <Input
            className="rangeInput"
            type="text"
            min="0"
            max="50"
            value={bigLetters}
            onChange={(event) => setBigLetters(event.target.value)}
          />{' '}
          stk
        </h4>
        <input
          className="slider lettersBigSlider"
          type="range"
          min="0"
          max="50"
          value={bigLetters}
          onChange={(event) => setBigLetters(event.target.value)}
        />

        <h2 id="lastPrice" className="headlines price">
          Prisen for skiltet:{' '}
          <strong>
            {width * length * 0.25 + smallLetters * 20 + bigLetters * 35}
          </strong>{' '}
          kr
        </h2>
      </div>
    </Container>
  );
}

export default Calculator;
